import IconComponent from "components/Icon";
import styles from "./styles.module.scss";

const BtnAgendamentoComponent = (): JSX.Element => {
  return (
    <div className={styles.btnAgendamentoWrapper}>
      <button className={styles.btnAgendamento}>
        <IconComponent icon="whatsapp" noMargin className={styles.icon} />
      </button>
      <div className={styles.options}>
        <a href="https://wa.me/5527996207901" target="_blank" rel="noreferrer">
          Centro Médico Jardins – Vitória
        </a>
        <a href="https://wa.me/5527992050803" target="_blank" rel="noreferrer">
          Instituto Lílie – Vila Velha
        </a>
      </div>
    </div>
  );
};

export default BtnAgendamentoComponent;
